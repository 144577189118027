body {
}

* {
  margin: 0;
  padding: 0;
  font-family: "Italiana", sans-serif;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  scroll-snap-type: y mandatory;
}

.section {
  height: calc(100vh);
  min-height: calc(100vh);
  width: 100%;
  /* scroll-snap-align: center; */
  overflow: hidden;
}

.section.with-bg {
  background-size: cover;
  position: relative;
  /* background-blend-mode: overlay; */
  background-position: top center;
}

.section > div {
  /* mix-blend-mode: difference; */
}

@font-face {
  font-family: "Halimun";
  src: local("Halimun"), url(./fonts/Halimun.ttf) format("truetype");
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.music-box-wrapper {
  position: fixed;
  display: flex;
  /* height: 100vh;
  width: 100vw; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  background-color: #896c52;
  overflow: hidden;
}

.music-box {
  position: relative;
  top: -150px;
}

.paper {
  width: 180px;
  height: 100px;
  background-color: wheat;
}


#music.open ~ .box-inside {
  transform: translateY(-115px);
}

.box-inside {
  position: absolute;
  transition: 0.5s;
  top: 110px;
  left: -10px;
}

.inner {
  background-color: #1a232a;
  position: absolute;
  width: 200px;
  height: 40px;
  border-radius: 20px 20px 0 0;
  top: 158px;
  left: -95px;
}

.box {
  position: absolute;
  width: 270px;
  background-color: #7c293d;
  height: 140px;
  top: 170px;
  left: -145px;
  z-index: 3;
  border-radius: 0 0 5px 5px;
}

.box:before {
  content: "Invitation";
  position: absolute;
  font-size: 30px;
  font-family: "Brush Script MT", cursive;
  bottom: 10px;
  right: 15px;
}

.music {
  position: absolute;
  width: 270px;
  background-color: #8d334d;
  height: 50px;
  border-radius: 10px 10px 0 0;
  z-index: 10;
  top: 120px;
  left: -145px;
  transform-origin: left;
  transition: 0.5s;
  cursor: pointer;
}

#music.open .music {
  transform: rotate(-125deg) translateX(-20px) translateY(-30px);
}

.shadow {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  width: 350px;
  height: 30px;
  border-radius: 50%;
  top: 300px;
  left: -185px;
  transition: 0.5s;
  transform-origin: right;
}

#music.open .shadow {
  transform: scaleX(1.4);
}
